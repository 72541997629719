import React from 'react';
import { Container, Accordion, Button, Card, Carousel } from 'react-bootstrap';
import P5Wrapper from 'react-p5-wrapper';
import sketch from '../sketches/homeSketch';
import headshot1 from '../assets/aboutMe.jpg';
import headshot2 from '../assets/masr1.jpg';
import ma7shi from '../assets/ma7shi.jpg';
import book from '../assets/book.jpg';
import avatar from '../assets/avatar.jpg';
import Song from './Song';

class AboutMe extends React.Component {
  constructor() {
    super();
    this.state = {
      nextPage: null,
      extension: false,
      facts: [
        {
          title: 'Favorite Food',
          link: 'https://www.food.com/recipe/grape-leaves-egyptian-version-253275',
          pic: ma7shi,
          description: 'Grape Leaves',
          id: 1,
        },
        {
          title: 'Favorite TV Show',
          link: 'https://en.wikipedia.org/wiki/Avatar:_The_Last_Airbender',
          pic: avatar,
          description: 'Avatar the Last Airbender',
          id: 2,
        },
        {
          title: 'Currently Reading',
          link: 'https://www.goodreads.com/book/show/23846205-how-to-relax',
          pic: book,
          description: 'How to Relax by Thich Nhat Hanh',
          id: 3,
        },
      ],
      pictures: [{ id: 1, pic: headshot1 }],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({
      nextPage: '/portfolio',
      extension: true,
      facts: this.state.facts,
    });
  }
  render() {
    return (
      <Container fluid className="inner2">
        <Container className="body-top inner2">
          <h1 className="about-header">About Me</h1>

          <Carousel
            interval={null}
            slide={true}
            keyboard={false}
            className="aboutPics"
          >
            {this.state.pictures.map((picture) => {
              if (picture.pic) {
                return (
                  <Carousel.Item key={picture.id}>
                    <img src={picture.pic} alt="" className="headshot" />
                  </Carousel.Item>
                );
              }
              if (picture.vid) {
                return (
                  <Carousel.Item key={picture.id}>
                    <video
                      preload="auto"
                      controls
                      className="Video"
                      // poster={poster}
                    >
                      <source src={picture.vid} type="video/mp4" />
                    </video>
                  </Carousel.Item>
                );
              }
            })}
          </Carousel>
          <div className="about-text">
            <div className="about-words">
              Hi, I'm Wesam! I was born and raised in NYC and still live here
              today. While completing my B.A. in Economics at NYU, I discovered
              my passion for coding. I completed the Grace Hopper Program at
              Fullstack Academy and learned to build full stack web applications
              with Node.js, Express, PostgreSQL, and React. I am always looking
              to explore new technologies. My aspiration is to lend a hand to my
              local and global communities whenever I can.
            </div>
          </div>
          <div className="line-break"></div>
          <br></br>
          <h2 className="about-header">Fun Facts</h2>
          <Accordion>
            {this.state.facts.map((fact) => {
              return (
                <div key={fact.title}>
                  <Card className="fact-card project-card">
                    <Accordion.Toggle
                      as={Card.Header && Button}
                      variant="info"
                      eventKey={fact.id}
                    >
                      <div>{fact.title}</div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={fact.id}>
                      <Card.Body className="inner2">
                        <Card.Img
                          variant="top"
                          src={fact.pic}
                          className="fact-pic"
                        />
                        <Card.Link
                          href={fact.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {fact.description}
                        </Card.Link>
                        {fact.pic2 && (
                          <div className="inner2">
                            <br />
                            <Card.Img
                              variant="top"
                              src={fact.pic2}
                              className="fact-pic"
                            />
                            <Card.Link
                              href={fact.link2}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {fact.description2}
                            </Card.Link>
                          </div>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              );
            })}
          </Accordion>
        </Container>
        <div className="line-break"></div>
        <br></br>
        <h2 className="about-header">Currently Listening</h2>
        <Song />
        <br></br>
        <div className="game-board">
          <P5Wrapper
            sketch={sketch}
            nextPage={this.state.nextPage}
            extension={this.state.extension}
          />
        </div>
      </Container>
    );
  }
}

export default AboutMe;
