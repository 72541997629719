import history from '../history';
import bird1 from '../assets/bird.png';
import bird2 from '../assets/bird2.png';
import coinPic from '../assets/coin.png';

export default function sketch(p) {
  let windowHeight = window.innerHeight;
  let windowWidth = window.innerWidth;
  let xValue = windowWidth / 10;
  let yValue = windowHeight / 3;
  let nextPage;
  let currentBird;
  let birdRight;
  let birdLeft;
  let coin1;
  let coin2;
  let coinX = windowWidth / 10 + 300;
  let coinY = windowHeight / 3 - 15;
  let coin2X = coinX + 300;
  let coin2Y = coinY + 30;
  let coinWidth = 105;
  let gameWon = false;
  let extension = false;
  let home = false;

  p.preload = () => {
    birdRight = p.loadImage(bird1);
    birdLeft = p.loadImage(bird2);
    coin1 = p.loadImage(coinPic);
    coin2 = p.loadImage(coinPic);
  };

  p.setup = () => {
    windowHeight = window.innerHeight;
    windowWidth = window.innerWidth;
    if (extension) {
      windowHeight *= 1.65;
    }
    p.createCanvas(windowWidth, windowHeight);
    p.noStroke();
    p.image(birdRight, xValue, yValue);
    currentBird = birdRight;

    if (home) {
      p.image(coin1, coinX, coinY);
      p.image(coin2, coin2X, coin2Y);
    }
  };

  p.windowResized = () => {
    windowHeight = window.innerHeight;
    windowWidth = window.innerWidth;
    if (extension) {
      windowHeight *= 1.65;
    }
    p.resizeCanvas(windowWidth, windowHeight);
    p.noStroke();
    p.redraw();
  };

  p.draw = () => {
    p.clear();
    p.image(currentBird, xValue, yValue);
    if (home) {
      if (coin1) p.image(coin1, coinX, coinY, coinWidth, coinWidth);
      if (coin2) p.image(coin2, coin2X, coin2Y, coinWidth, coinWidth);
      coinWidth += Math.random() * 1.2;
      coinWidth -= Math.random() * 1.2;
    }
  };

  p.keyPressed = () => {
    windowHeight = window.innerHeight;
    windowWidth = window.innerWidth;
    //move bird
    if (p.keyCode === p.LEFT_ARROW) {
      currentBird = birdLeft;
      xValue -= windowWidth / 15;
    } else if (p.keyCode === p.RIGHT_ARROW) {
      currentBird = birdRight;
      xValue += windowWidth / 15;
    } else if (p.keyCode === p.UP_ARROW) {
      yValue -= windowHeight / 15;
    } else if (p.keyCode === p.DOWN_ARROW) {
      yValue += windowHeight / 15;
    }

    //shut off game borders
    if (extension) windowHeight *= 1.65;
    //right border
    if (xValue > windowWidth - windowWidth / 11) {
      history.push(nextPage);
      if (gameWon) {
        xValue -= windowWidth / 15;
      }
      //left border
    } else if (xValue < windowWidth / 10) {
      xValue += windowWidth / 15;
      //bottom border
    } else if (yValue > windowHeight - windowHeight / 11) {
      yValue -= windowHeight / 15;
      //top border
    } else if (yValue < windowHeight / 20) {
      yValue += windowHeight / 15;
    }

    //collect coins
    if (
      home &&
      xValue <= coinX + 100 &&
      xValue >= coinX - 100 &&
      yValue <= coinY + 100 &&
      yValue >= coinY - 100
    ) {
      coin1 = null;
    }
    if (
      home &&
      xValue <= coin2X + 100 &&
      xValue >= coin2X - 100 &&
      yValue <= coin2Y + 100 &&
      yValue >= coin2Y - 100
    ) {
      coin2 = null;
    }
  };

  p.myCustomRedrawAccordingToNewPropsHandler = function (props) {
    if (props.nextPage) {
      nextPage = props.nextPage;
    } else if (props.gameWon) {
      gameWon = true;
    }
    if (props.extension) {
      extension = true;
    }
    if (props.home) {
      home = true;
    }
  };
}
