import React from 'react';
import { Navbar } from 'react-bootstrap';
import Socials from './Socials';

function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <Navbar bg="clear" variant="light" className="footer">
      <div className="inner2 w100">
        <Socials size={3} />
        <Navbar.Text>© Copyright {year} - Wesam Abdelzaher</Navbar.Text>
      </div>
    </Navbar>
  );
}

export default Footer;
