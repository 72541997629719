import React from 'react';
import { Container, Button, Carousel } from 'react-bootstrap';
import P5Wrapper from 'react-p5-wrapper';
import sketch from '../sketches/homeSketch';
import exit from '../assets/exit.png';
import findIt from '../assets/findIt.png';
import githubIcon from '../assets/github.svg';
import charter from '../assets/charter.png';

class Portfolio extends React.Component {
  constructor() {
    super();
    this.state = {
      nextPage: null,
      extension: false,
      projects: [
        {
          pic: exit,
          title: 'Exit',
          description:
            'An escape the room game compatible with web, mobile and VR platforms. First person player in 360° environments interacts with animated 3D objects to find clues to escape.\nTech Stack: React 360, React Native, Redux.',
          link: 'https://stormlooper.github.io/Exit/',
          gitLink: 'https://github.com/StormLooper/Exit',
          id: 0,
        },
        {
          pic: findIt,
          title: 'Find It',
          description:
            'An iOS/Android cross platform mobile application that enables users to scan a food product’s barcode to detect ingredients.\nTech Stack: React Native, Expo, Redux, AWS Amplify, AWS AppSync, GraphQL, DynamoDB, Open Food Facts API.',
          link: 'https://github.com/wewesemsem/Stackathon-FindIT',
          gitLink: 'https://github.com/wewesemsem/Stackathon-FindIT',
          id: 1,
        },
        {
          pic: charter,
          title: 'Quick-Chart',
          description:
            'A web graph application enabling users to upload CSV files and select options to easily plot line-graphs, bar-graphs, pie charts, and histograms.\nTech Stack: Python, React, Django REST framework, Pandas, NumPy, Matplotlib.',
          link: 'https://github.com/wewesemsem/quick-chart',
          gitLink: 'https://github.com/wewesemsem/quick-chart',
          id: 2,
        },
      ],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    let state = { ...this.state };
    state.nextPage = '/contact';
    state.extension = true;
    this.setState(state);
  }
  render() {
    return (
      <Container fluid className="inner2">
        <Container className="body-top inner2">
          <h1 className="about-header"> Portfolio</h1>
          <Carousel
            slide={true}
            interval={null}
            keyboard={false}
            className="project-slide project-card"
          >
            {this.state.projects.map((project) => {
              return (
                <Carousel.Item key={project.id}>
                  <div className="inner2">
                    <img className="project-pic" src={project.pic} alt="" />
                    <div>
                      <Button
                        href={project.link}
                        size="lg"
                        active
                        variant="outline-warning"
                        className="body-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {project.title}
                      </Button>
                      <Button
                        variant="clear"
                        href={project.gitLink}
                        className="body-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="social-link-5"
                          src={githubIcon}
                          alt=""
                        />
                      </Button>
                    </div>
                    <p className="project-words">{project.description}</p>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Container>
        <div className="game-board">
          <P5Wrapper
            sketch={sketch}
            nextPage={this.state.nextPage}
            // extension={this.state.extension}
          />{' '}
        </div>
      </Container>
    );
  }
}

export default Portfolio;
