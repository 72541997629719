import React from 'react';
import { Container } from 'react-bootstrap';
const songBase = 'https://open.spotify.com/embed/track/';
const songTrack = '4PWHrZKuhvpzNJMoZSXSc1';
const songSrc = songBase + songTrack;
const dedStr = '';

function Song() {
  return (
    <Container>
      <br></br>
      <div className="inner2">
        {/* <iframe
          src="https://www.youtube.com/embed/uoXyN9R51n8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          className="song body-link"
        ></iframe> */}
        <iframe
          title="todayssong"
          src={songSrc}
          className="song body-link"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
        <div className="dedication">{dedStr}</div>
        <br></br>
      </div>
    </Container>
  );
}

export default Song;
