import React from 'react';
import { Container } from 'react-bootstrap';
import P5Wrapper from 'react-p5-wrapper';
import sketch from '../sketches/homeSketch';
import keys from '../assets/keyboard.png';
import arrow from '../assets/arrow.png';
import MobileHome from './MobileHome';
import '../App.css';

class Home extends React.Component {
  constructor() {
    super();
    this.state = { nextPage: null, home: false };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ nextPage: '/aboutMe', home: true });
  }
  render() {
    return (
      <div>
        <div className="Home">
          <Container fluid className="inner2">
            <Container className="body-top">
              <div className="inner2">
                <div className="inner1 instructions">
                  <h1 className="home-text">Help me get home!</h1>{' '}
                  <img alt="" src={keys} className="keys" />
                </div>
                <div className="arrow-cont">
                  <img alt="" src={arrow} className="arrow" />
                </div>
              </div>
            </Container>
            <div className="game-board">
              <P5Wrapper
                sketch={sketch}
                nextPage={this.state.nextPage}
                home={this.state.home}
              />
            </div>
          </Container>
        </div>
        <MobileHome />
      </div>
    );
  }
}

export default Home;
