import React from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import Socials from './Socials';
import axios from 'axios';
import headerPic from '../assets/birdhouse.jpg';
import P5Wrapper from 'react-p5-wrapper';
import sketch from '../sketches/homeSketch';

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      endGame: false,
      name: '',
      email: '',
      message: '',
      submitted: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }
  handleChange(evt) {
    let state = { ...this.state };
    state[evt.target.id] = evt.target.value;
    this.setState(state);
  }
  async handleSubmit(evt) {
    evt.preventDefault();
    try {
      //send email
      await axios.post('/api/email/', this.state);
      this.setState({
        endGame: true,
        name: '',
        email: '',
        message: '',
        submitted: true,
      });
    } catch (err) {
      console.log(err);
    }
  }
  closeAlert() {
    let state = { ...this.state };
    state.submitted = false;
    this.setState(state);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let state = { ...this.state };
    state.endGame = true;
    this.setState(state);
  }
  render() {
    return (
      <div className="inner2">
        <Container className="body-top">
          <div className="Pic-wrap">
            <img src={headerPic} alt="" className="Wide-pic" />
          </div>
          <h1 className="about-header">Contact Me</h1>
          {this.state.submitted && (
            <Alert
              className="w70"
              variant="success"
              onClose={this.closeAlert}
              dismissible
            >
              Thanks for reaching out! I will get back to you soon.
            </Alert>
          )}
          <Form className="w70" onSubmit={this.handleSubmit}>
            <Form.Group controlId="name">
              <Form.Control
                size="lg"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Control
                type="email"
                size="lg"
                placeholder="E-mail"
                value={this.state.email}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Control
                as="textarea"
                rows="5"
                placeholder="Message"
                value={this.state.message}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Button variant="info" type="submit">
              Send
            </Button>
          </Form>
          <Socials size={7} />
        </Container>

        <div className="game-board">
          <P5Wrapper sketch={sketch} gameWon={this.state.endGame} />
        </div>
      </div>
    );
  }
}

export default Contact;
