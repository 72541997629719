import React from 'react';
import { Button } from 'react-bootstrap';
import linkedinIcon from '../assets/linkedin.svg';
import githubIcon from '../assets/github.svg';
import emailIcon from '../assets/email-icon.png';

class Socials extends React.Component {
  constructor() {
    super();
    this.state = {
      socials: [
        {
          title: 'LinkedIn',
          link: 'https://www.linkedin.com/in/wesam-abdelzaher/',
          pic: linkedinIcon,
        },
        {
          title: 'Github',
          link: 'https://github.com/wewesemsem',
          pic: githubIcon,
        },
        {
          title: 'Email',
          link: 'mailto:wesam.abdelzaher0@gmail.com?subject=Hello&body=Website',
          pic: emailIcon,
        },
      ],
    };
  }
  render() {
    const sizeClass = 'social-link social-link-' + this.props.size;
    return (
      <div className="socials body-link">
        {this.state.socials.map((social) => {
          return (
            <Button
              variant="outline-info"
              href={social.link}
              className="social-button"
              key={social.title}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="" src={social.pic} className={sizeClass} />
            </Button>
          );
        })}
      </div>
    );
  }
}

export default Socials;
