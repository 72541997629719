import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { AboutMe, Contact, Home, Portfolio } from './components';

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/aboutMe" component={AboutMe} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/portfolio" component={Portfolio} />
        {/* fallback */}
        <Route component={Home} />
      </Switch>
    );
  }
}

export default withRouter(Routes);
