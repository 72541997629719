import React from 'react';
import { Container, Button } from 'react-bootstrap';
import Socials from './Socials';
import bird from '../assets/bird.png';

class MobileHome extends React.Component {
  render() {
    return (
      <div className="mobile-home">
        <Container fluid className="inner2">
          <h1 className="home-text mobile-home-text">Welcome!</h1>
          <Button variant="clear" href={'/aboutMe'} className="bird-button">
            <img alt="" src={bird} className="bird" />
          </Button>
          <Socials size={7} />
        </Container>
      </div>
    );
  }
}

export default MobileHome;
